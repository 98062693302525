import React from "react";

import "./HeroSection.css";

export const HeroSection2 = () => {
  return (
    <>
      <div className="hero-heading-container">
        <h1 className="hero-title">+923003220839</h1>
        <h2 className="hero-subtitle">Contact Us At The Given Number .</h2>
      </div>
    </>
  );
};
